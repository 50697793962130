<template>
<div class="content-template content-template-fff" id="content-template">
  <div class="card animate__animated animate__fadeIn duration" id="card1" style="background:#000">
    <div class="card_box_one">
      <div class="card_box_title4">
        <p class="font-blod font-24">半年度主要<br />宏观经济数据</p>
        <p>{{dataTime}}</p>
      </div>
      <div class="width-100 height-100">
        <video
          src="video/video5.mp4"
          muted
          width="100%"
          height="100%"
          ref="video1"
        ></video>
      </div>
      <div class="logo-box">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="container container5 template4">
    <div class="audio-box">
      <audio src="audio/Skysand.mp3" ref="bgAudio"></audio>
      <!-- <audio :src="audioSrc" ref='audio1'></audio> -->
      <audio :src="audio1Src" ref='audio1'></audio>
      <audio :src="audio2Src" ref='audio2'></audio>
      <audio :src="audio3Src" ref='audio3'></audio>
      <audio :src="audio4Src" ref='audio4'></audio>
    </div>
    
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_two_box">
        <div class="card_header font-blod font-18">上半年国内生产总值（GDP)</div>
        <div class="content-top">
          <div class="upshow relative">
            <div class="top1" v-for="(item,index) in annual_cumulative" :key="index">
              <p>
                <span>{{index_name[index]}}</span>
                <span class="font-18 font-blod">{{item}} 亿元</span>
              </p>
              <p>
                <span>同比增长率</span>
                <span class="font-18 font-blod color-red">{{year_erlier_rate[index]}}%</span>
              </p>
            </div>
          </div>
          
        </div>
        <div class="page-video">
            <video
            src="video/page1.mp4"
            autoplay
            loop
            muted
            width="100%"
            height="100%"
          ></video>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_two_box">
        <div class="card_header font-blod font-18">上半年主要经济数据</div>
        <div class="content-top">
          <div>
            <div class="top2">
              <p>
                <span>居民消费价格指数（CPI）</span>
              </p>
              <p class="margin-t-10">
                <span>同比增长率</span>
                <span class="font-18 font-blod color-red margin-l-18">{{chart_data_2['居民消费价格（CPI）'][0]}}%</span>
              </p>
            </div>
          </div> 
        </div>
        <div class="page-video">
            <video
            src="video/page2.mp4"
            autoplay
            loop
            muted
            width="100%"
            height="100%"
          ></video>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="card_two_box">
        <div class="card_header font-blod font-18">上半年主要经济数据</div>
        <div class="content-top">
          <div class="top2 top2-1">
            <p>
              <span>工业生产者出厂价格指数（PPI）</span>
            </p>
            <p class="margin-t-10">
              <span>同比增长率</span>
              <span class="font-18 font-blod color-red margin-l-18">{{card3_1}}%</span>
            </p>
          </div>
          <div class="top2 top2-2 none">
            <p>
              <span>全国规模以上工业增加值</span>
            </p>
            <p class="margin-t-10">
              <span>同比增长率</span>
              <span class="font-18 font-blod color-red margin-l-18">{{card3_2}}%</span>
            </p>
          </div>
        </div>
        <div class="page-video">
            <video
            src="video/page3.mp4"
            autoplay
            loop
            muted
            width="100%"
            height="100%"
          ></video>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="card_two_box">
        <div class="card_header font-blod font-18">上半年主要经济数据</div>
        <div class="content-top">
          <div>
            <div class="top2 top3-1">
              <p>
                <span>社会消费品零售总额（亿元）</span>
              </p>
              <p class="margin-t-10">
                <span class="font-18 font-blod color-red margin-l-18">{{card5_1}}</span>
              </p>
            </div>
            <div class="top2 top3-2 none">
              <p>
                <span>全国居民人均可支配收入（元）</span>
              </p>
              <p class="margin-t-10">
                <span class="font-18 font-blod color-red margin-l-18">{{card5_3}}</span>
              </p>
            </div>
          </div> 
        </div>
        <div class="content-top margin-t-10">
          <div>
            <div class="top2 top3-1">
              <p>
                <span>全国固定资产投资（亿元）</span>
              </p>
              <p class="margin-t-10">
                <span class="font-18 font-blod color-red margin-l-18">{{card5_2}}</span>
              </p>
            </div>
            <div class="top2 top3-2 none">
              <p>
                <span>全国城镇新增就业人口（万人）</span>
              </p>
              <p class="margin-t-10">
                <span class="font-18 font-blod color-red margin-l-18">{{card5_4}}</span>
              </p>
            </div>
          </div> 
        </div>
        <div class="margin-t-10" id="chart1" style="width: 100%; height: 300px"></div>
        <div class="logo-box logo-box5">
          <img src="../assets/img/logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card6">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>
<script>
import $ from "jquery";
import { GetParams } from '../utils/common'
import ApiServe from '@/api/index'
export default {
  name: "Template5",
  data() {
    return {
      dataTime:localStorage.getItem('dataTime'),
      annual_cumulative:[],
      index_name:[],
      year_erlier_rate:[],
      chart_data_2: {
        '居民消费价格（CPI）':[]
      },
      card3_1:'',
      card3_2:"",
      card5_1:"",
      card5_2:"",
      card5_3:"",
      card5_4:"",
      chartData1:[],
      audioSrc: "",
      audio1Src:"",
      audio2Src:"",
      audio3Src:"",
      audio4Src:"",
      device_id: null,
      model_id: null,
      record_time: null,
      real: 1, //0测试, 1正式
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.model_id = GetParams().model_id || null;
    this.record_time = GetParams().record_time || 76;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { annual_cumulative, audio1Src } = this;
      return {
        annual_cumulative,
        audio1Src,
      };
    },
  },
  watch: {
    allReady(val) {
      if (val.annual_cumulative.length > 0 && val.audio1Src) {
        if (this.device_id && this.real) {
          setTimeout(() => {
            ApiServe.recordStart({
                model_id: this.model_id,
                device_id: this.device_id,
                record_time: this.record_time,
                real: this.real,
              }).then((res)=>{
                this.load();
              })
          }, 5000);
        } else {
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 5 }).then(({ data, code }) => {
          if (code == 200) {
            this.annual_cumulative = data.chart_data_1[0].data.annual_cumulative
            this.index_name = data.chart_data_1[0].data.index_name
            this.year_erlier_rate = data.chart_data_1[0].data.year_erlier_rate
            this.chart_data_2 = data.chart_data_2[0].data
            this.card3_1 = data.chart_data_3[0].data['工业生产者出厂价格（PPI）'][0]
            this.card3_2 = data.chart_data_3[1].data['Indus_Val_Add_Acc_Cha_Rat'][0]
            this.card5_1 = data.chart_data_4[0].data['社会消费品零售总额'][0]
            this.card5_2 = data.chart_data_4[1].data['全国固定资产投资'][0]
            this.card5_3 = data.chart_data_4[2].data['全国居民人均可支配收入'][0]
            this.card5_4 = data.chart_data_4[3].data['全国城镇新增就业人口'][0]
            this.chartData1 = data.chart_data_4[4].data['类别'].map((item,index)=>{
              return {
                value:Number(data.chart_data_4[4].data['占比'][index]),
                name:item,
                num:data.chart_data_4[4].data['累计值'][index]
              }
            })
          }
        }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: 5 }).then(({ data, code,dd }) => {
          if (code == 200) {
            //this.audioSrc = dd;
            this.audio1Src = data[0].audio_link
            this.audio2Src = data[1].audio_link
            this.audio3Src = data[2].audio_link
            this.audio4Src = data[3].audio_link
          }
        }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
     // 上半年主要经济数据
    loadChart1() {
      //var colorList = ['#E54444','#00B466','#9A9A9A']
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        legend: {
            top:'top',
            left:'18px',
            right:'18px'
            // orient: 'vertical',
            // left: 'right',
        },
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            data: this.chartData1,
            radius: '60%',
            center: ['50%', '65%'],
            roseType: 'radius',
            // left:18,
            // right:18,
            bottom:0,
            minAngle:'30',
            itemStyle: {
              normal: {
                label: {
                  // position:"inner",
                  textStyle: {
                    //数值样式
                    // color: "#AFF7FF",
                    fontSize: 14,
                  },
                  formatter: function (value) {
                    return value.data.num +'元'+'\n'+ (value.value).toFixed(2)+'%';
                  },
                },
                // color:function(params){
                //   return params.name == '预喜' ? colorList[0] : params.name == '预悲' ? colorList[1] :colorList[2]
                // },
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.video1.play(); //首页的视频播放
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio1.play()
        $('.upshow').animate({bottom:240},24000)
      }, 3000);
      setTimeout( ()=> {
        $("#card2").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio2.play()
      }, 29000);
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 34000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        $('.top2-1').animate({left:-300},11000)
        this.$refs.audio3.play()
      }, 35000);
      setTimeout(()=>{
        $('.top2-1').addClass('none')
        $('.top2-2').removeClass('none')
      },40000)
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 47000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.loadChart1()
        $('.top3-1').animate({left:-300},16000)
        this.$refs.audio4.play()
      }, 48000);
      setTimeout(() => {
        $('.top3-1').addClass("none")
        $('.top3-2').removeClass("none")
      }, 54000);
      setTimeout( ()=> {
        $("#card3").addClass("animate__fadeOut");
      }, 70000);
      setTimeout( ()=> {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.$refs.endVideo.play()
      }, 71000);
      // 总时长76秒
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:5,device_id:this.device_id,real:this.real})
        }, 81000);   //间隔
      }

    },
  },
};
</script>
<style scoped>
/* .content-template{
  background-color: #fff;
} */
.content-template-back{
  background-color: #080706;
}
.card{
  background: #fff;
}
.logo-box5{
  bottom: 0;
}
.card_box_title4{
    width: 250px;
    height: 280px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform:translate(-50%,-50%);
    width: 250px;
    height: 230px;
    border: 1px solid rgba(255,255,255,0.6);
    text-align: center;
    color: #fff;
    z-index: 999;
  }
  .card_box_title4 p{
    margin: 50px 0;
  }
.content-template-fff{
  background-color: #fff;
}
</style>